import FormElementMixin from './FormElementMixin.js';

const formElementRelationMixin = {
    mixins: [FormElementMixin],
    props: {
        relationString: {
            type: String,
        },
        bundleId: {
            type: String,
        },
        definitionId: {
            type: String,
        },
        currentObjectId: {
            type: String,
        },
        relation: {
            type: Object,
        },
    },
    data() {
        return {
            currentRelation: null,
        }
    },
    computed: {
        /**
         * Check if we need to render the relation resolver for this control
         * @returns {boolean}
         */
        renderRelationResolver() {
            let relation = this.getActiveRelation();
            if(! relation) {
                return false;
            }

            const fieldId = relation.shift();

            if (fieldId === this.id) {
                return true;
            }

            return false;
        },
        /**
         * Get the current relation strign
         * @returns {*}
         */
        currentRelationString() {
            let relation = this.getActiveRelation();
            relation.shift();
            return relation.join(':');
        },
        /**
         * Get the selected items in the current relation
         * @returns {*}
         */
        selectedItems() {
            this.currentRelation.selected.forEach((item) => {
                item.widgets = {...this.relation.widgets}
            });

            return this.currentRelation.selected;
        },
        /**
         *
         * @returns {Array}
         */
        selectedIds() {
            return this.currentRelation.selected.length ?
                    this.currentRelation.selected.map(item => { return item.id }) :
                    [];
        },
    },
    methods: {
        /**
         * Get the active relation
         * @returns {*}
         */
        getActiveRelation() {
            let relationString = this.relationString;

            if(! relationString) {
                return false;
            }

            return relationString.split(':');
        },
        /**
         * Update the current relation now that new ids have been selected
         * @param data
         */
        async updateRelations(idsSelected) {
            const path = `/${this.bundleId}/${this.definitionId}/select-ids`;

            let param = {
                id: this.currentObjectId,
            }

            // if we don't use the list we need to merge the selected Id every time
            if(! this.enableForeignList) {
                idsSelected = [...this.currentRelation.selected.map(selectedItem => selectedItem.id), ...idsSelected];

                // make sure we have unique ids
                idsSelected = [...new Set(idsSelected)];

                param.foreignIds = idsSelected.join(',');
            }


            param.foreignIds = idsSelected.join(',');

            // notify the backend of the selection so we get an updated relation.selected array
            const relation = await this.$get(path, param);

            if(relation) {

                // Keep the addOnValues
                let addOnValues = [];

                // Keep all the current addOnValues
                for(const key in this.currentRelation.selected) {
                    // Add each existing addOnValue in an array
                    addOnValues[this.currentRelation.selected[key].id] = this.currentRelation.selected[key].addOnValues;
                }

                // Set the new addOnValues of the new selection
                for(const key in relation.selected) {
                    let selectedRelation = relation.selected[key];
                    let selectedId = selectedRelation.id;

                    // Check if we have addOnValue from the previous selection
                    if(addOnValues.hasOwnProperty(selectedId)) {
                        relation.selected[key].addOnValues = addOnValues[selectedId];
                    }
                }

                this.currentRelation = relation;
            }
        },
        /**
         *
         * @param id
         */
        deleteSelected(id) {
            // remove the item from the selected array
            this.currentRelation.selected = this.currentRelation.selected.filter((item, key) => item.id != id);
        },
    },
    created() {
        // we need to disconnect the relation prop from the current relation
        // state otherwise the selected items wil change every time another relation is opened
        this.currentRelation = this.relation;
    }
}

export default formElementRelationMixin;