// UTILS
// Loading Async components
import './utils/async-component';

// Helpers
import {helpersPlugin} from './utils/helpers';
Vue.use(helpersPlugin);

// Directives
import './utils/directives/click-outside';

// Settings
import {settingsPlugin} from './utils/settings';
Vue.use(settingsPlugin);

// HTTP client
import dataManager from './utils/data-manager';
Vue.use(dataManager);

// Polyglot translations
import { fetchDictionary, translatePlugin } from './utils/polyglot';

// Filters
import {formatPlugin} from './utils/vue-filters';
Vue.use(formatPlugin);

// Action handler
import actionHandler from './utils/action-handler';
Vue.use(actionHandler);

// HTTP client
import localeHandler from './utils/locale-handler';
Vue.use(localeHandler);


// DEPENDENCIES
import draggable from "vuedraggable";
Vue.component(draggable);

// Vue2 datepicker
import DatePicker from './assets/vendor/vue2-datepicker/index';
import './assets/vendor/vue2-datepicker/locale/nl';
import './assets/vendor/vue2-datepicker/locale/en';
import './assets/vendor/vue2-datepicker/locale/fr';
import './assets/vendor/vue2-datepicker/locale/de';

// v-maks directive
import { VueMaskDirective } from './assets/vendor/v-mask/dist/v-mask'
Vue.directive('mask', VueMaskDirective);

// Vue Upload component
import VueUploadComponent from "vue-upload-component";
Vue.component('file-upload', VueUploadComponent);

// Redactor X
import './assets/vendor/redactorx/redactorx.js';

import './assets/vendor/vue-redactorx/vue-redactorx.min.js';
import './assets/vendor/redactorx/languages/nl.js';
import './assets/vendor/redactorx/languages/en.js';
import './assets/vendor/redactorx/plugins/alignment/alignment.js';
import './assets/vendor/redactorx/plugins/clips/clips.js';
import './assets/vendor/redactorx/plugins/imageposition/imageposition.js';
import './assets/vendor/redactorx/plugins/imageresize/imageresize.js';
import './assets/vendor/redactorx/plugins/inlineformat/inlineformat.js';
import './assets/vendor/redactorx/plugins/removeformat/removeformat.js';
import './assets/vendor/redactorx/plugins/textexpander/textexpander.js';
import './assets/vendor/redactorx/plugins/definedlinks/definedlinks.js';
import './utils/redactor-plugins/full-screen.js';
// import './assets/vendor/redactor/plugins/video/video.min.js';
// import './assets/vendor/redactor/plugins/alignment/alignment.min.js';
// import './assets/vendor/redactor/plugins/fullscreen/fullscreen.min.js';
// import './assets/vendor/redactor/plugins/table/table.min.js';
// import './assets/vendor/redactor/plugins/imagemanager/imagemanager.min.js';

// vue-select
import vSelect from './assets/vendor/vue-select/dist/vue-select'
Vue.component('v-select', vSelect);

// moment
import moment from 'moment';
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

// Vue cookies
import VueCookies from './assets/vendor/vue-cookies/vue-cookies';
Vue.use(VueCookies);

// vue-color
import { Chrome } from './assets/vendor/vue-color/dist/vue-color.min.js';
Vue.component('vue-color', Chrome);


// COMPONENTS
// Data
import './components/grid/AkDataWrapper';
import './components/grid/layout/AkGridFab';
import './components/grid/AkFilters';
import './components/grid/filters/AkFiltersDropdown';
import './components/grid/filters/AkFiltersDropdownItem';
import './components/grid/AkDataTableBody';
import './components/grid/AkRelationDataTable';
import './components/grid/AkExport';
import './components/grid/AkExportDialog';
import './components/grid/layout/AkWidgetDialog';
import './components/grid/layout/AkPageJumper'; 
import './components/grid/layout/AkWidgetList';
import './components/grid/layout/AkContextualWidgets';
import './components/grid/layout/AkHeaderContextualMenu';
import './components/grid/search/AkSearch';
import './components/grid/AkDataList';

// Layout
import './components/layout/AkModal';
import './components/layout/AkButton';
import './components/layout/AkIconButton';
import './components/layout/AkLink';
import './components/layout/AkHtml';
import './components/layout/AkChip';
import './components/layout/AkFab';
import './components/layout/AkConfirm';
import './components/layout/AkSnackbar';
import './components/layout/AkMenuItem';
import './components/layout/AkErrors';
import './components/layout/AkPopoverMenu';
import './components/layout/partials/AkPopoverMenuItem';
import './components/layout/partials/AkPopoverMenuDivider';
import './components/layout/AkTooltip';
import './components/layout/AkCard';
import './components/layout/progress/AkProgressLinear';
import './components/layout/loaders/AkLoader';
import './components/layout/loaders/AkSkeletonLoader';
import './components/layout/AkDraggableModal';
import './components/layout/AkTabs';
import './components/layout/AkButtonGroup';
import './components/layout/AkList';
import './components/layout/AkText';
import './components/layout/AkStatus';

// Wrappers
import './components/wrappers/AkWidget';
import './components/wrappers/AkHeader';
import './components/wrappers/AkDrawer';
import './components/wrappers/AkMenu';
import './components/wrappers/AkRelationResolver';
import './components/wrappers/AkWrapper';

// Form
import './components/form/AkForm';
import './components/form/AkFormGroup';
import './components/form/AkFormDecorator';
import './components/form/controls/AkTextArea';
import './components/form/controls/AkInputText';
import './components/form/controls/AkInputPassword';
import './components/form/controls/AkNumber';
import './components/form/controls/AkSelect';
import './components/form/controls/AkDatePicker';
import './components/form/controls/AkCheckbox';
import './components/form/controls/AkUpload';
import './components/form/controls/AkTimePicker';
import './components/form/controls/AkRelation';
import './components/form/controls/AkCheckboxes';
import './components/form/controls/AkRadio';
import './components/form/controls/AkSlug';
import './components/form/controls/AkWysiwyg';
import './components/form/controls/AkToggle';
import './components/form/controls/AkDateTimePicker';
import './components/form/controls/AkColorPicker';
import './components/form/controls/AkMatrix';
import './components/form/controls/AkAutocomplete';
import './components/form/controls/AkAutoCompleteByDefinition';
import './components/form/controls/AkAddress';
import './components/form/controls/AkPrice';
import './components/form/controls/AkInputTextWithPlaceholders';
import './components/form/controls/AkTextareaWithPlaceholders';
import './components/form/controls/partials/AkPlaceholderList';
import './components/form/controls/AkCode';
import './components/form/controls/AkWidgetBehindIcon';
import './components/form/controls/AkFormTabs';

// Locale
import './components/locale/AkLocalizationButton';

// Utilities
import './components/utilities/AkInViewport';
import './components/utilities/AkStackedWindow';
import './components/utilities/T';
import './components/utilities/AkUiKit.js';
import './components/utilities/AkConfirmUnsavedChanges.js';

class AppKit {
    init(app) {
        this.app = app;
        this.assignCssVariables();
        this.initPolyglot();
    }

    getApp() {
        return this.app
    }
    
    initPolyglot() {
        // Log the config file in dev mode
        if (process.env.NODE_ENV === 'development') {
            console.log('DEV: Config file: ', appKitSettings);
        }

        let lang = Vue.prototype.$getSetting('defaultLocale');
        Vue.prototype.$moment.locale(lang);

        Vue.use(translatePlugin);
    }
    assignCssVariables() {
        let styles = Vue.prototype.$getSetting('styles');

        Object.entries(styles).forEach(this.assignCssVar, this);

        let assets = Vue.prototype.$getSetting('assets');
        Object.entries(assets).forEach(this.assignCssVar, this);
    }

    assignCssVar([key, value]) {
        // convert camelCase to kebab-case
        key = key.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);

        // check if the value is a url
        if(value.indexOf('http') != -1 || value.indexOf('https') != -1 ) {

            value = 'url("' + value + '")';
        }

        // register the css var
        document.documentElement.style.setProperty('--' + key, value);
    }
}

window.AppKit = new AppKit();
