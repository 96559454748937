import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-date-time-picker', {
    mixins: [FormElementMixin],
    props: {
        position: {
            type: Object,
        }
    },
    data() {
        return {
            removeFromListener: ['input','updateState','blur'],
            selectedDate: null,
            selectedTime: null,
            dateFormat: 'DD/MM/YYYY',
            timeFormat: 'HH:mm'
        }
    },
    watch: {
        selectedDate(newValue) {
            if(this.$moment(this.currentValue).format(this.dateFormat) !== this.$moment(newValue).format(this.dateFormat)) {
                this.dateChanged();
            }
        },
        selectedTime(newValue) {
            const currentValueFormatted = this.currentValue ? this.$moment(this.currentValue).format(this.timeFormat) : null;
            const newValueFormatted = newValue ? newValue : null;
            if(currentValueFormatted !== newValueFormatted) {
                this.dateChanged();
            }
        },
        currentValue(newValue) {
            if (! newValue) {
                return;
            }

            if(this.$moment(newValue).format(this.dateFormat) !== this.$moment(this.selectedDate).format(this.dateFormat) ||
                this.$moment(newValue).format(this.timeFormat) !== this.timeFormat
            ) {
                this.selectedDate = this.$moment(this.currentValue).toDate();
                this.selectedTime = this.$moment(this.currentValue).format(this.timeFormat);
            }
        }
    },
    methods: {
        timeMask(value) {
            const hours = [
                /[0-2]/,
                value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/,
            ];
            const minutes = [/[0-5]/, /[0-9]/];
            return value.length > 2
                    ? [...hours, ':', ...minutes]
                    : hours;
        },
        dateChanged() {
            // if no time is set we set default to 00:00
            if (!this.selectedTime && this.selectedDate) {
                this.selectedTime = this.$moment('00:00', 'HH:mm').format(this.timeFormat);
            }

            if(this.selectedDate && (this.selectedTime && this.selectedTime.length == 5)) {
                this.currentValue = this.$moment(
                        this.$moment(this.selectedDate).format(this.dateFormat) + ' ' + this.selectedTime,
                        this.dateFormat + ' ' + this.timeFormat
                ).toISOString(true);
            }
            this.blur();
        },
        clearTime() {
            this.selectedDate = null;
            this.selectedTime = null;
            this.currentValue = null;
        }
    },
    created() {
        if(this.currentValue) {
            this.selectedDate = this.$moment(this.currentValue).toDate();
            this.selectedTime = this.$moment(this.currentValue).format(this.timeFormat);
        }
    }
}, 'form/controls/ak-date-time-picker.html');
