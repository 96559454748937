
Vue.asyncComponent('ak-in-view-port', {
    data() {
        return {
            inViewPort: false,
            scrollPos: 0
        }
    },
    props: {
        tolerance: {
            type: Number,
            default: 0
        },
        toleranceX: Number,
        toleranceY: Number,
    },
    watch: {
        // if inViewPort value is changed emit the corresponding event
        inViewPort(val) {
            if (val) this.$emit('appear');
            else this.$emit('disappear');
        }
    },
    methods: {
        isInViewport() {
            // check if there is an specific X or Y tolerance set
            const toleranceX = this.toleranceX != undefined ? this.toleranceX : this.tolerance;
            const toleranceY = this.toleranceY != undefined ? this.toleranceY : this.tolerance;

            // get the current element
            let el = this.$refs.inViewPort;

            // check if getBoundingClientRect is supported in this browser
            if (typeof el.getBoundingClientRect != 'undefined') {
                var rect = el.getBoundingClientRect();

                // check if ower rectangle is fully in viewport
                return (
                        Math.floor(rect.top) - toleranceY <= (window.innerHeight || document.documentElement.clientHeight) &&
                        Math.floor(rect.left) - toleranceX <= (window.innerWidth || document.documentElement.clientWidth) &&
                        Math.floor(rect.bottom) - toleranceY >= 0 &&
                        Math.floor(rect.right) - toleranceX >= 0

                );
            }

            let top = el.offsetTop;
            let left = el.offsetLeft;
            const width = el.offsetWidth;
            const height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                    top - toleranceY <= (window.pageYOffset + window.innerHeight) &&
                    left - toleranceX <= (window.pageXOffset + window.innerWidth) &&
                    (top + height - toleranceY) >= window.pageYOffset  &&
                    (left + width - toleranceX) >= window.pageXOffset
            );
        },
        checkInViewPort() {
            if (this.isInViewport()) {
                this.inViewPort = true;
            } else {
                this.inViewPort = false;
            }
        }
    },
    mounted() {
        this.checkInViewPort();
    },
    created() {
        // bind the scroll event to checkInViewPort
        window.addEventListener('scroll', this.checkInViewPort);
        // Perform primary check after DOM has been created
        this.$nextTick(() => this.checkInViewPort());
    },
    destroyed() {
        // unbind the scroll event to checkInViewPort
        window.removeEventListener('scroll', this.checkInViewPort);
    }
}, 'utilities/ak-in-view-port.html');