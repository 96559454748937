import FormElementMixin from '../mixins/FormElementMixin.js';

Vue.asyncComponent('ak-toggle', {
    mixins: [FormElementMixin],
    props: {
        size: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            selected: this.value
        }
    },
    watch: {
        selected(newValue, oldValue) {
            this.currentValue = this.selected;

            if (newValue !== oldValue) {
                this.$emit('toggle', this.selected);
            }
        }
    }
}, 'form/controls/ak-toggle.html');