import FormElementMixin from '../mixins/FormElementMixin.js';
import codemirror from 'codemirror';

Vue.asyncComponent('ak-wysiwyg', {
    mixins: [FormElementMixin],
    props: {
        configOptions: {
            type: Object|Array,
            required: true
        },
    },
    computed: {
        currentConfigOptions() {
            return {
                ...this.configOptions,
                image: {
                    ...this.configOptions.image,
                    upload: this.$getSetting('paths.baseAppKit') + this.configOptions.image.upload
                },
                editor: {
                    lang: this.$localeHandler.getActiveLocale(),
                    ...this.configOptions.editor,
                },
                codemirrorSrc: codemirror,
                codemirror: {
                    lineWrapping: true,
                    lineNumbers: true,
                    theme: this.darkModeActive ? 'material-ocean' : 'default',
                    mode: 'text/html',
                    readOnly: this.readOnly,
                    matchBrackets: true,
                    autoCloseBrackets: true,
                    autoCloseTags: true,
                    matchTags: {
                        bothTags: true
                    }
                },
                placeholder: this.placeholder,
                subscribe: {
                    'editor.focus': this.focus,
                    'editor.blur': this.blur,
                    // fix for bug where we save the value in codeEditor the changes won't come true
                    'source.change': (event) => {
                        this.currentValue = event.params.e.getValue();
                    },
                    ...this.$currentListeners
                }
            }
        },
        /**
         * Check if we are in dark mode
         * @returns {boolean|null|watch.darkMode|*}
         */
        darkModeActive() {
            if (! this.$darkModeHandler) {
                return false;
            }

            return this.$darkModeHandler.darkMode;
        }
    }
}, 'form/controls/ak-wysiwyg.html');