import FormElementMixin from '../mixins/FormElementMixin.js';
import MatrixFormTrigger from "../triggers/MatrixFormTrigger";

Vue.asyncComponent('ak-matrix', {
    mixins: [FormElementMixin],
    data() {
        return {
            currentValues: []
        }
    },
    props: {
        controls: {
            type: Array,
            required: true,
        },
        controlErrors: {
            type: Array,
        },
        sortingEnabled: {
            required: false,
        },
        addLineEnabled: {
            required: false,
        },
        deleteLineEnabled: {
            required: false,
        },
        widths: {
            type: Array,
            required: false
        }
    },
    watch: {
        currentValues() {
            let values = [...this.currentValues];
            if(this.value !== values) {
                this.currentValue = values;
            }
        }
    },
    computed: {
        widthsCssVariable() {
            if ( ! this.widths) {
                return null;
            }
            return '--matrix-grid-auto-columns:' + JSON.parse(this.widths).join(' ') + ';';
        }
    },
    methods: {
        /**
         * Add a new row to the matrix
         */
        addRow() {
            this.addValueObject();
        },
        /**
         * Remove an item from the values by key
         * @param key
         */
        removeRow(key) {
            this.currentValues.splice(key, 1);
        },
        /**
         * We push an extra value row
         */
        addValueObject() {
            this.$nextTick(() => {
                this.currentValues.push(this.getValueObject());
            });
        },
        /**
         * generate the value object depending on the controls given
         * @returns {{}}
         */
        getValueObject() {
            let obj = {};

            obj.valueId = Date.now();
            this.controls.forEach(control => {
                // we use the value fo the given formControl in case we have a default value set
                obj[control.id] = null;
            });

            return obj;
        },
        /**
         * Generate the widget attributes for the matrix columns
         * @param widget
         * @param row
         * @returns {*&{id, title: null}}
         */
        widgetAttributes(widget, row) {
            let attributes = {
                id: widget.id,
                ...widget.formControl,
                ...widget.attributes,
                title: null,
                value: this.currentValues[row][widget.id]
            };

            if (this.controlErrors && this.controlErrors[row] && this.controlErrors[row][widget.id]) {
                attributes = {...attributes, ...this.controlErrors[row][widget.id]};
            }

            return attributes;
        },
        validateChildWidget($event, $childIds) {
            this.$emit('blur', new MatrixFormTrigger($childIds))
        }
    },
    created() {
        if ( this.value && this.value.length) {
            // if we have values we assume it is in the correct format
            this.currentValues = this.value;
        } else {
            // we add a value object for 1 row because default we show 1 row
            this.addValueObject();
        }
    }
}, 'form/controls/ak-matrix.html');
