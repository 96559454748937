import operationHandler from './helpers/operation-handler';
import {deepEqual, isObject} from './helpers/object-helpers';
import {isInt, isFloat} from "./helpers/number-helpers";
import {debounce} from "./helpers/function-helpers";
import { copyToClipboard } from './helpers/copy-to-clipboard';
import ConfirmUnsavedChangesHandler from './helpers/confirm-unsaved-changes-handler';
import GlobalConfirm from "./helpers/global-confirm";

// create a confirmHandler for the whole application to use
const confirmUnsavedChangesHandler = (new ConfirmUnsavedChangesHandler());

export const helpersPlugin = {
    install: (Vue) => {
        Vue.prototype.$operationHandler = (value, opration, valueToCompare) => new operationHandler(value, opration, valueToCompare);
        Vue.prototype.$copyToClipboard = copyToClipboard;
        Vue.prototype.$deepEqual = deepEqual;
        Vue.prototype.$isObject = isObject;
        Vue.prototype.$isInt = isInt;
        Vue.prototype.$isFloat = isFloat;
        Vue.prototype.$confirmUnsavedChangesHandler = confirmUnsavedChangesHandler;
        Vue.prototype.$globalConfirm = Vue.observable(new GlobalConfirm());
        Vue.prototype.$debounce = debounce;
    }
}